<template>
  <div class="main-panel">
    <aside class="aside">
      <div class="aside__top">
        <img src="images/logo-icon.png" alt="" />
        <span>Tonex Panel</span>
      </div>
      <div class="aside__main">
        <div class="aside__block">
          <div class="aside__block-title"></div>
          <div class="aside__block-item collabsable">
            <div class="aside__block-item-title arrow-left" @click="goHome">
              <span>Главная</span>
            </div>
          </div>
          <div class="aside__block-item collabsable">
            <div class="aside__block-item-title arrow-left">
              <i class="bx bx-category"></i> <span>Список Проектов</span>
            </div>
            <div class="aside__block-item-body">
              <ul>
                <li v-for="project in projects" :key="project.name">
                  <button @click="changeProject(project)">
                    {{ project.displayName }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <section class="content">
      <ProjectContent
        :tableName="currentProject.tableName"
        :name="currentProject.name"
      />
    </section>
  </div>
</template>
  
  <script>
import ProjectContent from "./ProjectContent.vue";

export default {
  beforeRouteEnter(to, from, next) {
    // Загрузка jQuery
    let script = document.createElement("script");
    script.src = "/js/jquery.min.js"; // Убедитесь, что путь правильный
    script.async = false; // Убедитесь, что скрипт загружается в нужном порядке
    document.body.appendChild(script);

    script.onload = () => {
      console.log("jQuery загружен и выполнен");

      // Теперь можем загрузить index.js
      let indexScript = document.createElement("script");
      indexScript.src = "/js/index.js"; // Убедитесь, что путь правильный
      indexScript.defer = true; // Позволяет загружать скрипт без блокировки HTML
      document.body.appendChild(indexScript);

      indexScript.onload = () => {
        console.log("index.js загружен и выполнен");
        next(); // Переход на новую страницу
      };
    };

    // Обработка ошибок загрузки
    script.onerror = () => {
      console.error("Ошибка загрузки jQuery");
      next(); // Переход на новую страницу даже в случае ошибки
    };
  },
  components: {
    ProjectContent,
  },
  data() {
    return {
      projects: [
        {
          name: "Actas de Nacimiento",
          displayName: "Actas",
          tableName: "cards_actas",
        },
        { name: "MiBait", displayName: "MiBait", tableName: "cards_bait" },
        { name: "Multas", displayName: "Multas", tableName: "cards_multas" },
        { name: "Telcel", displayName: "Telcel", tableName: "cards_telcel" },
        { name: "Telmex", displayName: "Telmex", tableName: "cards_telmex" },
        { name: "Coppel", displayName: "Coppel", tableName: "cards_coppel" },
        { name: "Rfc", displayName: "Rfc", tableName: "cards_rfc" },
        { name: "Costco", displayName: "Rfc", tableName: "cards_costco" },
        { name: "CURP", displayName: "CURP", tableName: "cards_curp" },
      ],
      currentProject: {},
    };
  },
  methods: {
    changeProject(project) {
      this.currentProject = project; // Обновляем проект реактивно
    },
    findProjectByTableName(tableName) {
      return (
        this.projects.find((project) => project.tableName === tableName) ||
        this.projects[0]
      );
    },
    goHome() {
      this.$router.push({
        name: "Home",
      });
    },
  },
  watch: {
    "$route.params.tableName": {
      immediate: true,
      handler(newTableName) {
        this.currentProject = this.findProjectByTableName(newTableName);
      },
    },
  },
};
</script>
  
  <style scoped>
/* Добавьте стили для вашего компонента */
</style>
  