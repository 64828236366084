<template>
  <div class="hello">
    <img src="/images/logo-icon.png" alt="" />
    <h1>Welcome to Tonex Panel</h1>
    <h1>PROJECTS:</h1>
    <div class="projects">
      <ProjectCard
        v-for="project in projects"
        :key="project.tableName"
        :project="project"
      />
    </div>
    <div class="timer">
      <svg class="progress" viewBox="0 0 100 100">
        <circle class="progress-bg" cx="50" cy="50" r="45" />
        <circle
          class="progress-bar"
          cx="50"
          cy="50"
          r="45"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="offset"
        />
      </svg>
      <div class="timer-text">{{ currentTime }}</div>
    </div>
    <div class="seo-btn" @click="openSeo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 24 24"
        style="fill: rgba(255, 255, 255, 0.5); transform: ; msfilter: "
      >
        <path
          d="M4.626 8.878a7.937 7.937 0 0 1 1.71-2.541 7.92 7.92 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.49 2.49 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059-1.19.5-2.26 1.22-3.18 2.139A9.98 9.98 0 0 0 2 12h2c0-1.086.211-2.136.626-3.122zm14.747 6.244c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.973 9.973 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"
        ></path>
        <path
          d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538c2.502 0 4.538-2.036 4.538-4.538S14.502 7.462 12 7.462z"
        ></path>
      </svg>
    </div>
    <div class="settings-btn" @click="openSettings">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 24 24"
        style="fill: rgba(255, 255, 255, 0.5); transform: ; msfilter: "
      >
        <path
          d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"
        ></path>
        <path
          d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z"
        ></path>
      </svg>
    </div>
    <div class="settings" :class="{ active: isSettings }">
      <div class="settings-x" @click="closeSettings">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 24 24"
          style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
        >
          <path
            d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
          ></path>
        </svg>
      </div>
      <div class="settings__btns">
        <div class="settings__check">
          <div class="btn-wrap">
            <div id="settings-check" class="settings__btn" @click="openSelect">
              Select
            </div>
          </div>
          <div class="settings__check-info">
            <div>Selected Projects:</div>
            <div class="settings__check-info-box">
              <span v-for="(project, index) in selectedProjects" :key="index">
                {{ project.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="settings__btns-bot">
          <div class="download__section">
            <div class="btn-wrap">
              <div
                id="settings-download"
                class="settings__btn"
                @click="exportData()"
              >
                Download
              </div>
            </div>
            <ToggleButton v-model="digitalValue" value="3">All</ToggleButton>
            <ToggleButton v-model="digitalValue" value="1"
              >Digital</ToggleButton
            >
            <ToggleButton v-model="digitalValue" value="0"
              >Default</ToggleButton
            >
          </div>

          <div id="settings-delete" class="settings__btn" @click="deleteData">
            Delete
          </div>
        </div>
      </div>
    </div>
    <div class="select" :class="{ active: isSelect }">
      <div class="select__inner">
        <div class="select__box" :class="{ active: isBox }">
          <div class="settings-x" @click="closeSelect">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 24 24"
              style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
            >
              <path
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              ></path>
            </svg>
          </div>
          <div class="select__title">Select Projects</div>
          <div class="select__items">
            <SelectComponent
              v-for="project in projects"
              :key="project.tableName"
              :project="project"
              @select="updateStats"
            />
          </div>
          <div class="select__box-info">
            <div class="select__total">
              Total: <span>{{ select_totalCards }}</span>
            </div>
            <div class="select__digital">
              Digital: <span>{{ select_digitalCards }}</span>
            </div>
            <div class="select__physical">
              Physical: <span>{{ select_physicalCards }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCard from "./ProjectCard.vue";
import axios from "axios";
import SelectComponent from "./SelectComponent.vue";
import ToggleButton from "./ToggleButton.vue";

export default {
  components: {
    ProjectCard,
    SelectComponent,
    ToggleButton,
  },
  data() {
    return {
      projects: [
        {
          name: "Actas",
          description: "Описание проекта 1",
          tableName: "cards_actas",
        },
        {
          name: "Mibait",
          description: "Описание проекта 2",
          tableName: "cards_bait",
        },
        {
          name: "Multas",
          description: "Описание проекта 3",
          tableName: "cards_multas",
        },
        {
          name: "Telcel",
          description: "Описание проекта 4",
          tableName: "cards_telcel",
        },
        {
          name: "Telmex",
          description: "Описание проекта 5",
          tableName: "cards_telmex",
        },
        {
          name: "Coppel",
          description: "Описание проекта 6",
          tableName: "cards_coppel",
        },
        {
          name: "Rfc",
          description: "Описание проекта 6",
          tableName: "cards_rfc",
        },
        {
          name: "Costco",
          description: "Описание проекта 6",
          tableName: "cards_costco",
        },
        {
          name: "CURP",
          description: "Описание проекта 6",
          tableName: "cards_curp",
        },
      ],
      currentTime: 1,
      circumference: 2 * Math.PI * 45,
      offset: 0,
      interval: null,
      select_totalCards: 0,
      select_digitalCards: 0,
      select_physicalCards: 0,
      selectedProjects: [],
      isSettings: false,
      isSelect: false,
      isBox: false,
      digitalValue: "3",
    };
  },
  methods: {
    async exportData() {
      if (this.selectedProjects.length === 0) {
        alert("Пожалуйста, выберите хотя бы один проект.");
        return;
      }
      // Формируем параметры для запроса, передаем одно значение digital для всех проектов
      const queryParams = this.selectedProjects
        .map(
          (project) => `tableName[]=${encodeURIComponent(project.tableName)}`
        )
        .join("&");

      try {
        // Добавляем цифровое значение к запросу
        const response = await axios.get(
          `project/api/export_multiple.php?${queryParams}&digital=${this.digitalValue}`
        );

        // Создаем файл для скачивания
        const blob = new Blob([response.data], { type: "text/plain" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "exported_data.txt";
        link.click();
      } catch (error) {
        console.error("Ошибка при экспорте:", error);
      }
    },
    async deleteData() {
      if (this.selectedProjects.length === 0) {
        alert("Пожалуйста, выберите хотя бы один проект.");
        return;
      }

      try {
        const confirmed = confirm(
          "Вы уверены, что хотите удалить эти карточки?"
        );
        if (confirmed) {
          const tablesToDelete = this.selectedProjects.map(
            (project) => project.tableName
          );

          // Формируем данные для отправки на сервер
          const data = { tables: tablesToDelete };

          // Отправляем запрос на сервер через axios
          await axios.post("/project/api/clear_all_mass.php", data);
        }
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
        alert("Произошла ошибка при удалении данных!");
      }
    },
    openSeo() {
      this.$router.push({
        name: "Seo",
      });
    },
    openSettings() {
      this.isSettings = !this.isSettings;
    },
    closeSettings() {
      this.isSettings = !this.isSettings;
      this.isSelect = false;
      this.isBox = false;
    },
    openSelect() {
      this.isSelect = !this.isSelect;
      setTimeout(() => {
        this.isBox = true;
      }, 300);
    },
    closeSelect() {
      this.isSelect = !this.isSelect;
      this.isBox = false;
    },
    updateStats(selectedProject) {
      // Обновляем статистику для выбранного проекта

      const index = this.selectedProjects.findIndex(
        (p) => p.tableName === selectedProject.tableName
      );
      if (index === -1) {
        // Если проекта нет в списке, добавляем его
        this.selectedProjects.push(selectedProject);
        this.select_totalCards += selectedProject.totalCards;
        this.select_digitalCards += selectedProject.digitalTotal;
        this.select_physicalCards += selectedProject.inc;
      } else {
        // Если проект уже выбран, удаляем его
        this.selectedProjects.splice(index, 1);
        this.select_totalCards -= selectedProject.totalCards;
        this.select_digitalCards -= selectedProject.digitalTotal;
        this.select_physicalCards -= selectedProject.inc;
      }
    },
    startTimer() {
      this.currentTime = 0;
      this.offset = this.circumference; // Устанавливаем начальное значение
      this.fetchCards();
      this.interval = setInterval(() => {
        this.currentTime++;
        // Прогресс должен точно отобразить весь цикл от 0 до 5 секунд
        this.offset =
          this.circumference - this.circumference * (this.currentTime / 5);

        if (this.currentTime > 5) {
          this.currentTime = 0; // Сброс таймера после 5 секунд
          this.offset = this.circumference; // Сброс прогресс-бара
          this.fetchCards(); // Вызываем fetchCards каждые 5 секунд
        }
      }, 1000); // Обновляем каждую секунду
    },
    fetchCards() {
      // Здесь вызываем fetchCards для всех проектов
      this.projects.forEach(async (project) => {
        const response = await axios.get(`/project/api/cards.php`, {
          params: {
            table: project.tableName,
          },
        });
        // Здесь можно обновить статистику для каждого проекта
        project.totalCards = response.data.total;
        project.digitalTotal = response.data.digitalTotal;
        project.badTotal = response.data.badTotal;
        project.inc =
          project.totalCards - project.digitalTotal - project.badTotal;
      });
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.interval); // Очищаем таймер при уничтожении компонента
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  transition: all 0.3s;
  opacity: 0;
  z-index: -1;
  &.active {
    z-index: 1;
    opacity: 1;
  }
  &__title {
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &__inner {
    width: calc(100vw - 300px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__box {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(0px);
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.12) !important;
    width: calc(100% - 300px);
    height: 600px;
    border-radius: 40px;
    padding: 20px;
    position: relative;
    transition: all 0.3s;
    &.active {
      backdrop-filter: blur(25px);
    }
    .settings-x {
      top: 20px;
    }
    &-info {
      position: absolute;
      bottom: 20px;
      right: 50px;
      text-align: left;
      span {
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
      }
      div {
        margin-bottom: 10px;
      }
    }
  }
  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  &__item {
  }
}
.settings {
  background: rgba(0, 0, 0, 0.4);
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.12) !important;
  padding: 20px;
  position: absolute;
  right: -400px;
  top: 0;
  min-height: 100vh;
  width: 300px;
  z-index: 999;
  padding-top: 150px;
  transition: all 0.3s;
  &.active {
    right: 0;
  }
  #settings-delete {
    margin: 0 auto;
    margin-top: 25px;
  }
  #settings-check {
    position: relative;
    &::after,
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      top: 50%;
      transform: translateY(-50%);
      width: 100px;
      background: rgba(255, 255, 255, 0.4);
    }
    &::after {
      left: -115px;
    }
    &::before {
      right: -115px;
    }
  }
  &__check {
    width: 100%;
    margin-bottom: 25px;
    &-info {
      margin-top: -23px;
      text-align: left;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 10px 15px;
      border-top: none;
      padding-top: 35px;

      div:first-child {
        margin-bottom: 10px;
      }
      &-box {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-wrap: wrap;
      }
      span {
        padding: 5px;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -23px;
    overflow: hidden;
  }
  &__btns {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    &-bot {
      width: 100%;
      margin-top: 50px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      flex-direction: column;
      #settings-download {
        margin-bottom: 15px;
        position: relative;
        &::after,
        &::before {
          content: "";
          position: absolute;
          height: 1px;
          top: 50%;
          transform: translateY(-50%);
          width: 100px;
          background: rgba(255, 255, 255, 0.4);
        }
        &::after {
          left: -115px;
        }
        &::before {
          right: -115px;
        }
      }
    }

    .download__section {
      margin-bottom: 15px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-top: none;
      width: 100%;
      padding-bottom: 10px;
    }
  }
  &__btn {
    background-color: rgba(255, 255, 255, 0.4);
    color: #000;
    font-weight: 700;
    border-radius: 10px;
    padding: 13px 20px;
    max-width: 120px;
    width: 100%;
    transition: all 0.3s;
    cursor: pointer;
    border: 2px solid transparent;
    text-transform: uppercase;

    &:hover {
      color: #fff;
      background: #34bfa3;
    }
  }
}
.seo-btn {
  background-color: rgba(255, 255, 255, 0.4);
  color: #000;
  font-weight: 700;
  border-radius: 10px;
  padding: 13px 20px;
  max-width: 120px;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
  border: 2px solid transparent;
  text-transform: uppercase;

  &:hover {
    color: #fff;
    background: #34bfa3;
    svg {
      transform: rotate(360deg);
      path {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }
  position: absolute;
  right: 50px;
  top: 100px;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px;
  line-height: 0;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s;
  svg {
    transition: all 0.3s;
    path {
      transition: all 0.3s;
    }
  }
}
.settings-btn,
.settings-x {
  position: absolute;
  right: 50px;
  top: 50px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px;
  line-height: 0;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s;
  svg {
    transition: all 0.3s;
    path {
      transition: all 0.3s;
    }
  }
  &:hover {
    // border-color: rgba(255, 255, 255, 0.5);
    background: #34bfa3 !important;
    svg {
      transform: rotate(360deg);
      path {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }
}
.settings-x {
  background-color: rgba(255, 255, 255, 0.4) !important ;
}
.hello {
  padding-top: 50px;
  text-align: center;
}

.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1225px;
  margin: 0 auto;
  padding-left: 15px;
}

.timer {
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 50px;
  left: 25px;
}

.progress {
  transform: rotate(-90deg);
}

.progress-bg {
  fill: none;
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1;
}

.progress-bar {
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 1;
  transition: stroke-dashoffset 1s linear;
}

.timer-text {
  font-size: 5rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "safiro-medium", sans-serif;
}

@media (max-width: 800px) {
  .timer {
    display: none;
  }
}
</style>
