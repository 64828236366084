<template>
  <section class="seo">
    <img src="/images/logo-icon.png" alt="" />
    <h1>Seo Bot Network</h1>
    <div class="seo__items">
      <table>
        <thead>
          <tr>
            <th>Project</th>
            <th>Keyword</th>
            <th>Domain</th>
            <th>Threads</th>
            <th>Target Clicks</th>
            <th>Status</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in projects" :key="project.id">
            <td>{{ project.name }}</td>
            <td>{{ project.keyword }}</td>
            <td>{{ project.url }}</td>
            <td>{{ project.threads }}</td>
            <td>{{ project.current_clicks }} / {{ project.clicks }}</td>
            <td class="status" :class="`status-${project.status}`">
              {{ project.status }}
            </td>
            <td class="seo__btns">
              <button d-name="start" @click="startProject(project.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                >
                  <path
                    d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886z"
                  ></path>
                </svg>
              </button>
              <button d-name="pause" @click="pauseProject(project.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                >
                  <path
                    d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z"
                  ></path>
                </svg>
              </button>
              <button d-name="delete" @click="deleteProject(project.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                >
                  <path
                    d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
                  ></path>
                  <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                </svg>
              </button>
            </td>
          </tr>
          <tr class="seo__new" :class="{ active: isNew }">
            <td>
              <form @submit.prevent="createProject">
                <td>
                  <input
                    type="text"
                    v-model="newProject.name"
                    name="name"
                    placeholder="Проект"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="newProject.keyword"
                    name="keyword"
                    placeholder="Ключ"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="newProject.url"
                    name="url"
                    placeholder="Url"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="newProject.threads"
                    name="threads"
                    placeholder="Кол-во Потоков"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="newProject.clicks"
                    name="clicks"
                    placeholder="Кол-во Кликов"
                  />
                </td>
                <td>
                  <button type="submit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 24 24"
                      style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                    >
                      <path
                        d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"
                      ></path>
                    </svg>
                  </button>
                </td>
              </form>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="seo__add btn" @click="addNew">Add New</div>
    </div>
  </section>
</template>
  
<script>
export default {
  data() {
    return {
      projects: [],
      newProject: {
        id: 0,
        name: "",
        keyword: "",
        url: "",
        threads: 1,
        clicks: 0,
        current_clicks: 0,
        status: "inactive",
      },
      isNew: false,
    };
  },
  async created() {
    this.fetchProjects();
  },
  methods: {
    async fetchProjects() {
      const response = await fetch("project/api/get_seo_projects.php");
      this.projects = await response.json();
    },
    async createProject() {
      const response = await fetch("project/api/create_seo_project.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.newProject),
      });
      if (response.ok) {
        await this.fetchProjects();
        this.newProject = {
          name: "",
          keyword: "",
          url: "",
          threads: 1,
          clicks: 0,
          current_clicks: 0,
        };
      }
    },
    async deleteProject(id) {
      await this.pauseProject(id);
      const response = await fetch(
        `project/api/delete_seo_project.php?id=${id}`
      );
      if (response.ok) {
        await this.fetchProjects();
      }
    },
    addNew() {
      this.isNew = !this.isNew;
    },
    async startProject(id) {
      const project = this.projects.find((p) => p.id === id);
      if (!project) return;

      const response = await fetch(
        "https://apistatslink.com/project/api/seoserver/start-script",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "431747ae-1e0e-42a6-bdc1-9c1acc87bc26",
          },
          body: JSON.stringify({
            keyword: project.keyword,
            domain: project.url,
            projectId: project.id,
            threads: project.threads,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Script started:", result);
      } else {
        const error = await response.json();
        console.error("Error:", error);
      }
    },
    async pauseProject(id) {
      const response = await fetch(
        "https://apistatslink.com/project/api/seoserver/pause-script",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "431747ae-1e0e-42a6-bdc1-9c1acc87bc26",
          },
          body: JSON.stringify({ projectId: id }), // Используем существующий ID
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Script paused:", result);
      }
    },
    startTimer() {
      this.currentTime = 0;
      this.interval = setInterval(() => {
        this.currentTime++;
        // Прогресс должен точно отобразить весь цикл от 0 до 5 секунд
        this.offset =
          this.circumference - this.circumference * (this.currentTime / 5);

        if (this.currentTime > 5) {
          this.currentTime = 0; // Сброс таймера после 5 секунд
          this.fetchProjects();
        }
      }, 1000); // Обновляем каждую секунду
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.interval); // Очищаем таймер при уничтожении компонента
  },
};
</script>

<style lang="scss" scoped>
.seo {
  text-align: center;
  padding-top: 50px;
  padding: 50px;
  font-family: "safiro-medium", sans-serif;
  &__items {
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.12);
    margin: 0 auto;
    max-width: 1400px;
  }
  &__btns {
  }
  button {
    border: none;
    background-color: transparent;
    svg {
      cursor: pointer;
      padding: 5px;
      background-color: hsla(0, 0%, 100%, 0.4);

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      transition: all 0.3s;
      &:hover {
        background-color: #34bfa3;
        path {
          fill: #fff;
        }
      }
      path {
        fill: #fff;
        transition: all 0.3s;
      }
    }
  }
  &__new {
    display: block;
    overflow: hidden;
    padding: 0;
    transition: all 0.3s;
    max-height: 0;
    height: 100%;
    &.active {
      max-height: 1000px;
    }
    background: rgba(255, 255, 255, 0.15);
    td {
      padding: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      width: 300px;
    }

    input {
      background: transparent;
      border: none;
      border-bottom: 1px solid #34bfa3;
      color: #34bfa3;
      outline: none;
      text-align: center;
      padding: 10px;
      &:focus {
        color: #fff;
      }
      &::placeholder {
        color: hsla(0, 0%, 100%, 0.4);
      }
    }
  }
  .btn {
    background-color: hsla(0, 0%, 100%, 0.4);
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    padding: 13px 20px;
    max-width: 120px;
    width: 100%;
    transition: all 0.3s;
    cursor: pointer;
    border: 2px solid transparent;
    text-transform: uppercase;
    &:hover {
      color: #fff;
      background: #34bfa3;
    }
  }
  &__add {
    margin: 25px auto;
    max-width: 150px !important;
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: 50px;
  }
}

table {
  caption-side: bottom;
  border-collapse: collapse;
  border-spacing: 2px;
  --bs-table-bg: transparent;
  --bs-table-striped-color: rgb(255 255 255 / 85%);
  --bs-table-striped-bg: rgb(255 255 255 / 12%);
  --bs-table-active-color: rgb(255 255 255 / 27%);
  --bs-table-active-bg: rgb(255 255 255 / 10%);
  --bs-table-hover-color: rgb(255 255 255);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.85);
  vertical-align: top;
  border-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  thead {
    vertical-align: bottom;
    --bs-table-bg: rgb(255 255 255 / 15%);
    color: #fff;
    border-color: rgba(255, 255, 255, 0.15);
  }
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    padding: 5px;
    border-radius: 10px;
  }
  tr {
    width: 100%;
    display: flex; /* Делаем строку flex-контейнером */
    gap: 20px; /* Расстояние между ячейками */
    justify-content: space-between;
  }
  th {
    padding: 0.5rem 0.5rem;
    background-color: rgba(255, 255, 255, 0.15);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
    border-radius: 10px;
  }
  td {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 10px;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
    &.status {
      text-transform: capitalize;
    }
    &.status-inprocess {
      color: #79a1ff;
    }
    &.status-stopped {
      color: #e9c2e9;
    }
    &.status-success {
      color: #34bfa3;
    }
  }
  th,
  td {
    width: 100%;
  }
}
</style>
  